import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScriptsService {
  load(id: string, url: string) {
    const script = this.getScript(id);
    return script ? of(true) : this.loadScript(id, url);
  }

  private getScript(id: string) {
    return document.getElementById(id);
  }

  private loadScript(id: string, url: string): Observable<boolean> {
    const loader = new Subject<boolean>();
    const script = document.createElement('script');
    script.id = id;
    script.src = url;
    script.onload = () => {
      loader.next(true);
      loader.complete();
    };
    document.head.appendChild(script);

    return loader;
  }
}
